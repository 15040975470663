<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head back="Convention.Information" :title="item.name" :subtitle="item.description" />

	<app-content-body :white="true" :loading="is.loading && is.initialised">

		<app-blocks :blocks="data.blocks.collection" />

	</app-content-body>

</app-content>

</template>

<script>

import mixItem from '@/mixin/item'

export default {

	mixins: [mixItem],

	data: function() {

		return {
			data: {
				blocks: {}
			},
			endpoint: 'convention/information/page',
			live: 'convention/information/page'
		}

	}

}

</script>

<style scoped>

</style>